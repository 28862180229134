app-topbar {
  .topbar {
    background: white;
    border-bottom: 1px solid map-get($colors, light);

    .nav-item {
      @include transition;

      cursor: pointer;
      color: map-get($colors, greylight);

      &:hover .nav-link i {
        color: map-get($colors, primary);
      }
    }
  }
}
