app-navbar {
  .navbar {
    .ohq-nav-item {
      @include transition;

      &:hover,
      &.active {
        a {
          color: white;

          i {
            color: white;
          }
        }
      }
    }
  }

  #sidebar {
    color: #6e727b;

    &.navbar {
      position: absolute;

      .dropdown-divider {
        opacity: 0.1;
      }
    }
  }
}
