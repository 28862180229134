app-dashboard {
  .dashboard {
    &-chart {
      height: 200px;
    }

    .display-4 {
      font-size: 2rem;
    }

    &-boxes {
      .text-large {
        font-size: 2rem;
      }
    }
  }
}
