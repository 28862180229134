//- Modal
.modal {
  &-body {
    padding: 2rem;
  }

  &-header {
    padding: 2rem 2rem 0 2rem;
  }
}
