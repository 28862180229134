bs-daterangepicker-container {
  left: -225px !important;
}

.ohq-datepicker {
  .bs-datepicker-head,
  .selected,
  .select-start,
  .select-end {
    background-color: map-get($colors, primary);
  }
}
