ng-sidebar-container {
  height: 100vh !important;

  .ng-sidebar {
    height: 100vh;
    width: 70%;
    max-width: 950px;
    background: var(--bs-light);
    box-shadow: 0 0 10px rgba(map-get($colors, greylight), 0.15);
    z-index: 3 !important;

    &__backdrop {
      z-index: 3 !important;
    }
  }
}
