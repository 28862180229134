.ohq {
  &-dropzone {
    background: var(--bs-light);

    &-content {
      color: map-get($colors, primary);
      font-size: 0.8rem;
    }
  }
}
