//- Animations
.fadeIn {
  @include fadeIn();
}

.flash {
  @include flash();

  animation-iteration-count: infinite;
}

.shake {
  @include shake();
}
