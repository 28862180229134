app-checklist {
  .checklists {
    &-gallery {
      width: 150px;
      height: 150px;
      position: relative;

      button {
        display: none;
        position: absolute;
        z-index: 999;
        top: 1rem;
        right: 1rem;

        &:hover {
          display: block;
        }
      }

      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0.5;

        &:hover {
          opacity: 1;

          ~ button {
            display: block;
          }
        }
      }
    }
  }
}
