/* You can add global styles to this file, and also import other style files */
$colors: (
  primary: #ff5a5f,
  light: #ecf0f7,
  danger: #ea2e49,
  warning: #f57f17,
  success: #21ce99,
  greylight: #767676,
  dark: #000000
);

@import '../../../src/styles';
